export default class PQAPI {
  constructor({ apiDomain }) {
    this.apiDomain = apiDomain;
  }

  /**--------------------------------------------------------------------------------------------*\
   * Reused
  \**--------------------------------------------------------------------------------------------*/
  async postJson({ apiPath, body, $auth }) {
    const tokenAvailable = $auth && $auth.strategy.token.get();

    if (tokenAvailable) {
      return await fetch(this.apiDomain + apiPath, {
        method: 'post',
        body: JSON.stringify(body),
        mode: 'cors',
        credentials: 'include',
        withCredentials: true,
        headers: {
          'Authorization': $auth.strategy.token.get(),
          'Content-Type': 'application/json'
        }
      }).then(response => response.json());
    }

    return await fetch(this.apiDomain + apiPath, {
      method: 'post',
      body: JSON.stringify(body),
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => response.json());
  }

  async putJson({ apiPath, body, $auth }) {
    const tokenAvailable = $auth && $auth.strategy.token.get();

    if (tokenAvailable) {
      return await fetch(this.apiDomain + apiPath, {
        method: 'put',
        body: JSON.stringify(body),
        mode: 'cors',
        credentials: 'include',
        withCredentials: true,
        headers: {
          'Authorization': $auth.strategy.token.get(),
          'Content-Type': 'application/json'
        }
      }).then(response => response.json());
    }

    return await fetch(this.apiDomain + apiPath, {
      method: 'post',
      body: JSON.stringify(body),
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => response.json());
  }

  async delete({ apiPath, $auth }) {
    return await fetch(this.apiDomain + apiPath, {
      method: 'delete',
      mode: 'cors',
      credentials: 'include',
      withCredentials: true,
      headers: {
        'Authorization': $auth.strategy.token.get(),
        'Content-Type': 'application/json'
      }
    }).then(response => response.json());
  }

  async postFormData({ apiPath, formData, $auth }) {
    return await fetch(this.apiDomain + apiPath, {
      method: 'post',
      body: formData,
      mode: 'cors',
      credentials: 'include',
      withCredentials: true,
      headers: {
        'Authorization': $auth.strategy.token.get()
        // This is NOT JSON
      }
    }).then(response => response.json());
  }

  async get({ apiPath, $auth }) {
    const tokenAvailable = $auth && $auth.strategy.token.get();

    if (tokenAvailable) {
      return await fetch(this.apiDomain + apiPath, {
        method: 'get',
        mode: 'cors',
        credentials: 'include',
        withCredentials: true,
        headers: {
          'Authorization': tokenAvailable,
          'Content-Type': 'application/json'
        }
      }).then(result => result.json());
    }

    return await fetch(this.apiDomain + apiPath).then(result => result.json());
  }

  /**--------------------------------------------------------------------------------------------*\
   * API
  \**--------------------------------------------------------------------------------------------*/
  async uploadImage({ file, refId, ref, field, entryPlugin, $auth }) {
    const formData = new FormData();
    formData.append('files', file);
    formData.append('refId', refId);
    formData.append('ref', ref);
    formData.append('field', field);

    if (entryPlugin) {
      formData.append('source', entryPlugin);
    }

    console.log('Uploading Image', file, refId, ref, field, entryPlugin);

    return await this.postFormData({
      apiPath: '/upload-safe',
      formData,
      $auth
    });
  }

  async postChallenge({ ownerId, location, datePhotoTaken, dateEnabled, challengeTypeId, bonusMultiplier, challengeDetails, description, $auth }) {
    return await this.postJson({
      apiPath: '/challenges',
      body: {
        owner: ownerId,
        latitude: location ? location.latitude : 0,
        longitude: location ? location.longitude : 0,
        location_details: location ? location.details : {},
        date_photo_taken: datePhotoTaken,
        date_enabled: dateEnabled,
        challenge_type: challengeTypeId,
        bonus_multiplier: bonusMultiplier,
        challenge_details: challengeDetails,
        description
      },
      $auth
    });
  }

  async editChallenge({ id, location, datePhotoTaken, bonusMultiplier, challengeDetails, $auth }) {
    return await this.putJson({
      apiPath: `/challenges/${id}`,
      body: {
        longitude: location ? location.longitude : 0,
        latitude: location ? location.latitude : 0,
        location_details: location ? location.details : {},
        challenge_details: challengeDetails,
        date_photo_taken: datePhotoTaken,
        bonus_multiplier: bonusMultiplier
      },
      $auth
    });
  }

  async deleteChallenge({ id, $auth }) {
    return await this.delete({
      apiPath: `/challenges/${id}`,
      $auth
    });
  }

  async getRandomChallenges({ count, type, $auth }) {
    return await this.get({
      $auth,
      apiPath: `/challenges/random?count=${count}&type=${type}`
    });
  }

  async getChallengeAnswersByUser({ userId, $auth }) {
    return (await this.get({
      apiPath: `/challenge-answers/by-user/${userId}`,
      $auth
    })).challengeAnswers;
  }

  async getChallengeAnswerByChallenge({ challengeId, $auth }) {
    return (await this.get({
      apiPath: `/challenge-answers/by-challenge/${challengeId}`,
      $auth
    }));
  }

  async postChallengeAnswer({ $auth, challengeId, location, date_photo_taken, distanceUnit, answers }) {
    return await this.postJson({
      apiPath: `/challenge-answers/${challengeId}/answer`,
      body: {
        location,
        date_photo_taken,
        distanceUnit,
        answers
      },
      $auth
    });
  }

  async register({ username, email, password }) {
    return this.postJson({
      apiPath: '/auth/local/register',
      body: {
        username,
        email,
        password
      }
    });
  }
}