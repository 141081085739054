export const state = () => ({
  globalSettings: undefined,
  challengeTypes: [],
});

export const mutations = {
  setGlobalSettings(state, globalSettings) {
    state.globalSettings = globalSettings;
  },
  setChallengeTypes(state, challengeTypes) {
    state.challengeTypes = challengeTypes;
  }
}