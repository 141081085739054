<template>
  <div :class="`image-container ${classes}`">
    <img :src="src" />
  </div>
</template>

<script>
  import './ImageContainer.scss';

  export default {
    props: ['src', 'classes']
  };
</script>
