<template>
  <core-data-loader>
    <div class="page">
      <Header></Header>
      <div class="main">
        <nuxt />
      </div>
      <Footer />
    </div>
  </core-data-loader>
</template>

<script>
  import Header from "~/layouts/Header";
  import Footer from "~/layouts/Footer";
  import CoreDataLoader from "~/components/CoreDataLoader";
  
  import '../styles/base.scss';
  import '../layouts/default.scss';

  export default {
    components: {
      Header,
      Footer,
      CoreDataLoader
    },
    mounted() {
      // Setup Apollo Login JWT token, on startup. THe problem is that the auth-next module does
      // not automatically sync with Apollo... so we set that here.
      if (this.$auth.token) {
        this.$apolloHelpers.onLogin(this.$auth.token.replace('Bearer ', ''));
      }
    }
  };
</script>
