export default class PQRestAPI {
  constructor(vue) {
    this.vue = vue;
  }

  async reverseLonLat(lonLat, useHttps = false) {
    const protocol = useHttps ? 'https' : 'http';

    return fetch(`${protocol}://nominatim.openstreetmap.org/reverse?format=json&lon=${lonLat[0]}&lat=${lonLat[1]}`)
      .then(response => response.json());
  }
}