export const state = () => ({
  /**
   * For non-logged-in users, this is where we store state between routes.
   */
  lastChallenge: undefined
});

export const mutations = {
  setLastChallenge(state, lastChallenge) {
    state.lastChallenge = lastChallenge;
  }
}