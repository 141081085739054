<template>
  <div :class="headerClassDynamic">
    <div class="logo flex row space-between center">
      <div class="flex column space-between margin-left-2">
        <a class="name" href="/">{{ headerTitle }}</a>
        <div class="sub desktop">{{ headerSubTitleDesktop }}</div>
        <div class="sub mobile">{{ headerSubTitleMobile }}</div>
      </div>
    </div>
    <header-navigation />
  </div>
</template>

<script>
  import './Header.scss';
  import GlobalMixin from '~/mixins/GlobalMixin';
  import HeaderNavigation from '../components/HeaderNavigation.vue';

  export default {
    components: { HeaderNavigation },
    mixins: [GlobalMixin],
    data() {
      return {
        scrolled: false
      };
    },
    created () {
      if (process.client) window.addEventListener('scroll', this.handleScroll.bind(this));
    },
    destroyed () {
      if (process.client) window.removeEventListener('scroll', this.handleScroll.bind(this));
    },
    methods: {
      handleScroll(event) {
        this.scrolled = window.scrollY > 50;
      }
    },
    computed: {
      logoIconUrl() {
        return this.$globalSetting('logo_icon', 'url');
      },
      headerClassDynamic() {
        return 'header flex row space-between center' + (this.scrolled ? ' scrolled' : '');
      },
      headerTitle() {
        return this.$globalSetting('site_title');
      },
      headerSubTitleDesktop() {
        return this.$globalSetting('site_short_description');
      },
      headerSubTitleMobile() {
        return this.$globalSetting('site_short_description_mobile');
      },
      headerLinks() {
        return this.$links('header');
      }
    },
    watch: {
      '$store.state.global': () => {}
    }
  };
</script>
