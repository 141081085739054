import userByUsernameQuery from '~/apollo/queries/UserByUsername.gql';

export default async function ({ app }) {
  if (app.$auth.$state.loggedIn) {
    return await app.apolloProvider.defaultClient.query({
      query: userByUsernameQuery, 
      variables: { username: app.$auth.$state.user.username },
      fetchPolicy: 'network-only'
    }).then(userResult => {
      app.$auth.setUser(userResult.data.users[0]);
    });
  }
}