<template>
  <div class="footer">
    <div class="copyright">{{ copyright }}</div>
    <div class="version">{{$config.buildVersion}}</div>
  </div>
</template>

<script>
  import './Footer.scss';

  export default {
    data() {
      return {
        copyright: undefined
      }
    },
    mounted() {
      this.copyright = this.$store.state.global.globalSettings.footer_copyright;
    },
    watch: {
      '$store.state.global.globalSettings': function() {
        const { globalSettings } = this.$store.state.global;

        if (globalSettings) {
          this.copyright = globalSettings.footer_copyright;
        }
      }
    }
  };
</script>
