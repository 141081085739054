import userByUsernameQuery from '~/apollo/queries/UserByUsername.gql';

export default {
  methods: {
    $storeProperty: function (store, path, def = undefined) {
      const keys = path.split(".");
      let v = this.$store.state[store];
      keys.forEach(key => {
        if (v[key] !== undefined) v = v[key];
      });
      return (v === undefined || v === null) ? def : v;
    },
    $globalSetting: function (key, key2) {
      if (!this.$store.state || !this.$store.state.global || !this.$store.state.global.globalSettings) {
        return undefined;
      }

      try {
        if (key2) {
          return this.$store.state.global.globalSettings[key][key2];
        }
        return this.$store.state.global.globalSettings[key];
      } catch (error) {
        return undefined;
      }
    },
    $challengeTypeByType(type) {
      return this.$storeProperty('global', 'challengeTypes').find(ct => ct.type === type);
    },
    async reloadMe() {
      if (!this.$auth.user) {
        return;
      }

      return await this.$apolloProvider.defaultClient.query({
        query: userByUsernameQuery, 
        variables: { username: this.$auth.user.username }
      }).then(userResult => {
        const user = userResult.data.users[0];
        console.log('Refreshed user', user)
        this.$auth.setUser(user);
      });
    }
  }
}