<template>
  <div class="header-navigation">
    <div class="desktop">
      <div class="actions flex row space-between">
        <nuxt-link exact to="/"><i class="fas fa-home"></i></nuxt-link>
        <nuxt-link exact to="/play">Play</nuxt-link>
        <nuxt-link v-if="!$auth.user" exact to="/login">Login</nuxt-link>
        <nuxt-link v-if="!$auth.user" exact to="/register">Create Account</nuxt-link>
        <nuxt-link v-if="$auth.user" to="/me">
          <avatar :user="$auth.user" v-if="$auth.user"  />
        </nuxt-link>
      </div>
    </div>
    <div class="mobile flex row center">
      <nuxt-link exact to="/play">Play</nuxt-link>
      <div :class="mobileOverlayClass" @click="overlayClickHandler()" />
      <div :class="mobileActionsClass" @click="mobileHeader_clickHandler()">
        <div class="mobile-menu flex column">
          <nuxt-link exact to="/"><i class="fas fa-home"></i></nuxt-link>
          <nuxt-link exact to="/play">Play</nuxt-link>
          <nuxt-link v-if="!$auth.user" exact to="/login">Login</nuxt-link>
          <nuxt-link v-if="!$auth.user" exact to="/register">Create Account</nuxt-link>
          <nuxt-link v-if="$auth.user" exact to="/me">My Account</nuxt-link>
          <avatar :user="$auth.user" v-if="$auth.user" />
        </div>
        <div v-on:click="mobileBarsCloseClickHandler" class="close-button">
          <i class="fas fa-times" />
        </div>
      </div>

      <div v-on:click="mobileBarsClickHandler" class="open-menu-container flex row center">
        <avatar :user="$auth.user" v-if="$auth.user" :hideUsername="true" />
        <i class="open-button fas fa-bars" />
      </div>
    </div>
  </div>
</template>

<script>
  import './HeaderNavigation.scss';
  import GlobalMixin from '../mixins/GlobalMixin';
  import Avatar from "./Avatar";

  export default {
    mixins: [GlobalMixin],
    components: {
      Avatar
    },
    data() {
      return {
        mobileOpen: false
      }
    },
    computed: {
      mobileActionsClass() {
        return 'actions flex column ' + (this.mobileOpen ? 'show' : '');
      },
      mobileOverlayClass() {
        return 'overlay ' + (this.mobileOpen ? 'show' : '');
      }
    },
    methods: {
      mobileBarsClickHandler() {
        this.mobileOpen = true;
      },
      mobileBarsCloseClickHandler() {
        this.mobileOpen = false;
      },
      overlayClickHandler() {
        this.mobileOpen = false;
      },
      mobileHeader_clickHandler() {
        this.mobileOpen = false;
      }
    },
    watch: {
      '$auth.user': function() {
        console.log('User updated, refreshing Avatar', this.$auth.user);
        this.$forceUpdate();
      }
    }
  };
</script>
