<template>
  <div :class="`avatar ${classes || ''}`">
    <image-container :src="imageSrc" :classes="imageClasses" />
    <div class="username" v-if="!hideUsername">{{username}}</div>
    <div class="score" v-if="!hideScore && totalScore !== undefined">&nbsp;({{totalScore}})</div>
  </div>
</template>

<script>
  import './Avatar.scss';
  import ImageContainer from './ImageContainer';
  import { imageToSize } from '~/utils/imageUtilFrontend';

  export default {
    props: {
      user: [Object, Boolean],
      classes: String, 
      hideUsername: Boolean, 
      hideScore: Boolean
    },
    components: {
      ImageContainer
    },
    computed: {
      userActual() {
        return this.user;
      },
      username() {
        return this.userActual ? this.userActual.username : '';
      },
      totalScore() {
        if (this.userActual && this.userActual.status) {
          return this.userActual ? this.userActual.status.score.total : 0;
        } else {
          return 0;
        }
      },
      imageClasses() {
        return this.userActual && this.userActual.image ? '' : 'default';
      },
      imageSrc() {
        return this.userActual && this.userActual.image ? imageToSize(this.userActual.image, 'small') : '';
      }
    }
  };
</script>
