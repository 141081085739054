//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
//:::                                                                         :::
//:::  This routine calculates the distance between two points (given the     :::
//:::  latitude/longitude of those points). It is being used to calculate     :::
//:::  the distance between two locations using GeoDataSource (TM) prodducts  :::
//:::                                                                         :::
//:::  Definitions:                                                           :::
//:::    South latitudes are negative, east longitudes are positive           :::
//:::                                                                         :::
//:::  Passed to function:                                                    :::
//:::    lat1, lon1 = Latitude and Longitude of point 1 (in decimal degrees)  :::
//:::    lat2, lon2 = Latitude and Longitude of point 2 (in decimal degrees)  :::
//:::    unit = the unit you desire for results                               :::
//:::           where: 'M' is statute miles (default)                         :::
//:::                  'K' is kilometers                                      :::
//:::                  'N' is nautical miles                                  :::
//:::                                                                         :::
//:::  Worldwide cities and other features databases with latitude longitude  :::
//:::  are available at https://www.geodatasource.com                         :::
//:::                                                                         :::
//:::  For enquiries, please contact sales@geodatasource.com                  :::
//:::                                                                         :::
//:::  Official Web site: https://www.geodatasource.com                       :::
//:::                                                                         :::
//:::               GeoDataSource.com (C) All Rights Reserved 2018            :::
//:::                                                                         :::
//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

export function distance(lat1, lon1, lat2, lon2, unit = 'M') {
	if ((lat1 == lat2) && (lon1 == lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1/180;
		var radlat2 = Math.PI * lat2/180;
		var theta = lon1-lon2;
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit=="K") { dist = dist * 1.609344 }
		if (unit=="N") { dist = dist * 0.8684 }
		return dist;
	}
}

export function degreeToDecimal(value) {
	const deg = parseFloat(value[0]);
	const min = parseFloat(value[1]);
	const sec = parseFloat(value[2]);

	if (isNaN(deg) || isNaN(min) || isNaN(sec)) {
			return NaN;
	}

	return deg + (min / 60.0) + (sec / 3600);
}

// https://github.com/perfectline/geopoint/blob/master/geopoint.js
export const GeoPoint = function(lon, lat) {
	switch (typeof(lon)) {
			case 'number':
					this.lonDeg = this.dec2deg(lon, this.MAX_LON);
					this.lonDec = lon;
					break;
			case 'string':
					if (this.decode(lon)) {
							this.lonDeg = lon;
					}
					this.lonDec = this.deg2dec(lon, this.MAX_LON);
					break;
	}

	switch (typeof(lat)) {
			case 'number':
					this.latDeg = this.dec2deg(lat, this.MAX_LAT);
					this.latDec = lat;
					break;
			case 'string':
					if (this.decode(lat)) {
							this.latDeg = lat;
					}
					this.latDec = this.deg2dec(lat, this.MAX_LAT);
					break;
	}
};

GeoPoint.prototype = {
	CHAR_DEG : "\u00B0",
	CHAR_MIN : "\u0027",
	CHAR_SEC : "\u0022",
	CHAR_SEP : "\u0020",

	MAX_LON: 180,
	MAX_LAT: 90,

	// decimal
	lonDec: NaN,
	latDec: NaN,

	// degrees
	lonDeg: NaN,
	latDeg: NaN,

	dec2deg: function(value, max) {
			var sign = value < 0 ? -1 : 1;
			var abs = Math.abs(Math.round(value * 1000000));

			if (abs > (max * 1000000)) {
					return NaN;
			}

			var dec = abs % 1000000 / 1000000;
			var deg = Math.floor(abs / 1000000) * sign;
			var min = Math.floor(dec * 60);
			var sec = (dec - min / 60) * 3600;

			var result = "";

			result += deg;
			result += this.CHAR_DEG;
			result += this.CHAR_SEP;
			result += min;
			result += this.CHAR_MIN;
			result += this.CHAR_SEP;
			result += sec.toFixed(2);
			result += this.CHAR_SEC;

			return result;
	},

	deg2dec: function(value) {
			var matches = this.decode(value);

			if (!matches) {
					return NaN;
			}

			var deg = parseFloat(matches[1]);
			var min = parseFloat(matches[2]);
			var sec = parseFloat(matches[3]);

			if (isNaN(deg) || isNaN(min) || isNaN(sec)) {
					return NaN;
			}

			return deg + (min / 60.0) + (sec / 3600);
	},

	decode: function(value) {
			var pattern = "";

			// deg
			pattern += "(-?\\d+)";
			pattern += this.CHAR_DEG;
			pattern += "\\s*";

			// min
			pattern += "(\\d+)";
			pattern += this.CHAR_MIN;
			pattern += "\\s*";

			// sec
			pattern += "(\\d+(?:\\.\\d+)?)";
			pattern += this.CHAR_SEC;

			return value.match(new RegExp(pattern));
	},

	getLonDec: function() {
			return this.lonDec;
	},

	getLatDec: function() {
			return this.latDec;
	},

	getLonDeg: function() {
			return this.lonDeg;
	},

	getLatDeg: function() {
			return this.latDeg;
	}
};