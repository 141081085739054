<template>
  <div>
    <slot />
  </div>
</template>

<script>
  import globalSettingsQuery from "~/apollo/queries/GlobalSettings.gql";
  import challengeTypesQuery from "~/apollo/queries/ChallengeTypes.gql";

  export default {
    name: 'core-data-loader',
    data() {
      return {}
    },
    apollo: {
      globalSetting: {
        prefetch: true,
        query: globalSettingsQuery,
        result(result, key) {
          this.$store.commit('global/setGlobalSettings', result.data.globalSetting);
        }
      },
      challengeTypes: {
        prefetch: true,
        query: challengeTypesQuery,
        result(result, key) {
          this.$store.commit('global/setChallengeTypes', result.data.challengeTypes);
        }
      }
    }
  };
</script>
