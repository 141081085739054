import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _73bc3405 = () => interopDefault(import('../pages/me/index.vue' /* webpackChunkName: "pages/me/index" */))
const _19b35221 = () => interopDefault(import('../pages/play/index.vue' /* webpackChunkName: "pages/play/index" */))
const _5fba350d = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _14c887ea = () => interopDefault(import('../pages/me/edit/index.vue' /* webpackChunkName: "pages/me/edit/index" */))
const _da9fec8e = () => interopDefault(import('../pages/me/create/hunt.vue' /* webpackChunkName: "pages/me/create/hunt" */))
const _76bda1c4 = () => interopDefault(import('../pages/me/create/when-and-where.vue' /* webpackChunkName: "pages/me/create/when-and-where" */))
const _43166756 = () => interopDefault(import('../pages/me/edit/hunt/_id.vue' /* webpackChunkName: "pages/me/edit/hunt/_id" */))
const _0ca19b61 = () => interopDefault(import('../pages/me/edit/when-and-where/_id.vue' /* webpackChunkName: "pages/me/edit/when-and-where/_id" */))
const _321d4dfc = () => interopDefault(import('../pages/play/hunt/_id/index.vue' /* webpackChunkName: "pages/play/hunt/_id/index" */))
const _8ae74ce6 = () => interopDefault(import('../pages/play/when-and-where/_id/index.vue' /* webpackChunkName: "pages/play/when-and-where/_id/index" */))
const _5d6bbdf9 = () => interopDefault(import('../pages/play/hunt/_id/solution.vue' /* webpackChunkName: "pages/play/hunt/_id/solution" */))
const _202fed8e = () => interopDefault(import('../pages/play/when-and-where/_id/solution.vue' /* webpackChunkName: "pages/play/when-and-where/_id/solution" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4ec8857e = () => interopDefault(import('../pages/_username/index.vue' /* webpackChunkName: "pages/_username/index" */))
const _468d1718 = () => interopDefault(import('../pages/_username/challenges.vue' /* webpackChunkName: "pages/_username/challenges" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/login",
    component: _10ba8d22,
    name: "login"
  }, {
    path: "/me",
    component: _73bc3405,
    name: "me"
  }, {
    path: "/play",
    component: _19b35221,
    name: "play"
  }, {
    path: "/register",
    component: _5fba350d,
    name: "register"
  }, {
    path: "/me/edit",
    component: _14c887ea,
    name: "me-edit"
  }, {
    path: "/me/create/hunt",
    component: _da9fec8e,
    name: "me-create-hunt"
  }, {
    path: "/me/create/when-and-where",
    component: _76bda1c4,
    name: "me-create-when-and-where"
  }, {
    path: "/me/edit/hunt/:id?",
    component: _43166756,
    name: "me-edit-hunt-id"
  }, {
    path: "/me/edit/when-and-where/:id?",
    component: _0ca19b61,
    name: "me-edit-when-and-where-id"
  }, {
    path: "/play/hunt/:id",
    component: _321d4dfc,
    name: "play-hunt-id"
  }, {
    path: "/play/when-and-where/:id",
    component: _8ae74ce6,
    name: "play-when-and-where-id"
  }, {
    path: "/play/hunt/:id?/solution",
    component: _5d6bbdf9,
    name: "play-hunt-id-solution"
  }, {
    path: "/play/when-and-where/:id?/solution",
    component: _202fed8e,
    name: "play-when-and-where-id-solution"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/:username",
    component: _4ec8857e,
    name: "username"
  }, {
    path: "/:username/challenges",
    component: _468d1718,
    name: "username-challenges"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config.app && config.app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
